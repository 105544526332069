<template>
  <div class="event-pages-content">
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff;">
        <a-col :span="18">
          <a-skeleton :loading="!event" :paragraph="{ rows: 3 }" active>
            <h3 class="mb-0" v-if="event" style="font-size: 20px;">{{event.event_name}}</h3>
          </a-skeleton>
        </a-col>

        <a-col v-if="event" :span="6" class="text-right" style="display: flex; justify-content: flex-end;">
            <a-button type="primary" class="ml-4" size="large" @click="openPage(null)">
                <a-icon type="plus" /> Create new
            </a-button>
        </a-col>
    </a-row>
    <a-card v-if="event">
      <div class="link" v-if="link">
        <a-tooltip title="Copy to clipboard" placement="bottom">
          <a-icon type="copy" class="text-primary" @click="clipboardHandle(link)" style="cursor: pointer; font-size: 16px; padding: 2px 8px 0 0;" />
        </a-tooltip>
        <a target="_blank" :href="link">{{ link }}</a>
      </div>
        <a-table
          :columns="columns"
          :data-source="pages"
          :rowKey="(record, index) => index"
          :loading="tableLoading"
          :pagination="pagination"
          @change="tableChange"
          :customRow="customRow"
          :components="components"
        >
          <template slot="page_name" slot-scope="text, record">
            <span>
              {{ record.names[0].name }}
            </span>
          </template>
          <template slot="page_langs" slot-scope="text, record">
            <span v-for="lang in record.langs" :key="lang.lang_id">
              {{ lang.language.short }}
            </span>
          </template>
          <template slot="status" slot-scope="text, record">
            <span>
              <a-tag :key="record.status" :color="statusColor[record.status]">{{ record.status }}</a-tag>
            </span>
          </template>
          <template slot="actions" slot-scope="text, record">
            <div class="row-operations">
              <a-icon
                v-if="record.status === 'active'"
                type="bulb"
                theme="twoTone"
                two-tone-color="#31ba9a"
                class="font-size-18 mr-2"
              />
              <a-icon
                v-else
                type="bulb"
                theme="twoTone"
                two-tone-color="#ccc"
                class="font-size-18 mr-2"
              />
              <span>
                <a-popconfirm
                  title="Sure to delete this page?"
                  @confirm="() => deletePage(record.id)"
                >
                  <a class="font-size-18 text-danger delete-icon" href="javascript:;"><a-icon type="delete" /></a>
                </a-popconfirm>
              </span>
            </div>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import apiClient from '@/services/axios'
import DraggableWrapper from './DraggableWrapper'

const statusColor = { active: 'green', inactive: 'red' }

export default {
  data: () => ({
    link: null,
    loadingPage: false,
    statusColor,
    events: [],
    pagination: {
      pageSize: 7,
      current: 1,
      total: 0,
    },
    firstActivePage: null,
    tableLoading: false,
    components: {
      body: {
        wrapper: DraggableWrapper,
      },
    },
    columns: [
      {
        title: 'Order',
        dataIndex: 'index',
        width: '20%',
      },
      {
        title: 'Name',
        dataIndex: 'page_name',
        width: '40%',
        scopedSlots: { customRender: 'page_name' },
      },
      {
        title: 'Languages',
        dataIndex: 'page_langs',
        width: '20%',
        scopedSlots: { customRender: 'page_langs' },
      },
      {
        title: 'Actions',
        width: '20%',
        scopedSlots: { customRender: 'actions' },
      },
    ],
  }),
  watch: {
    sortUpdate: function(newVal, oldVal) {
      if (newVal === true) {
        this.tableUpdate()
      }
    },
  },
  computed: {
    ...mapFields('event', {
      event: 'event',
      pages: 'pagesArray',
      sortUpdate: 'sortUpdate',
    }),
  },
  methods: {
    clipboardHandle(v) {
      this.$clipboard(v)
    },
    handlerIsMainText(is) {
      if (is) return 'Главная'
    },
    fetchEvent(eventID) {
      const _this = this
      return new Promise((resolve, reject) => {
        try {
          apiClient.get(`admin/events/${eventID}`)
            .then(({ data }) => {
              setTimeout(() => {
                _this.event = data.data
                resolve(data.data)
              }, 314)
            })
        } catch (e) {
          console.error(e)
          reject(e)
        }
      })
    },
    customRow(page) {
      const _this = this
      return {
        on: {
          click: (e) => {
            if (e.target.closest('.delete-icon')) {
              return false
            }
            if (e.target.closest('.anticon-bulb')) {
              page.status = page.status === 'active' ? 'inactive' : 'active'
              this.editStatus(page)
              return false
            }
            _this.$router.push(`/events/${_this.event.id}/page-${page.id}`)
          },
        },
      }
    },
    async tableUpdate() {
      this.sortUpdate = false
      try {
        this.tableLoading = true
        await this.fetchEventPages()
      } catch (e) {
        console.error(e)
      } finally {
        this.tableLoading = false
      }
    },
    async editStatus(payload) {
      try {
        this.loading = true
        const {
          data,
        } = await apiClient.patch(
          `admin/events/${this.event.id}/pages/${payload.id}`,
          { ...payload },
        )
        this.page = data.data
        this.firstActivePage = this.pages.find(el => el.status === 'active')
        if (this.firstActivePage === undefined) {
          this.link = ''
        } else {
          this.link = `${this.event.link}/${this.event.default_language.short}/${this.firstActivePage.index}`
        }
      } catch (e) {
        this.$notification.error({
          message: 'Error',
          description: e.message,
        })
      } finally {
        this.loading = false
      }
    },
    async fetchEventPages() {
      const { current, pageSize } = this.pagination
      const { data } = await apiClient.get(`admin/events/${this.event.id}/pages?page=${current}&perPage=${pageSize}`)
      this.pages = data.data
      if (this.pages.length > 0) {
        const defaultLang = this.pages[0].langs.find(el => {
          return el.lang_id === this.event.default_lang_id
        })
        this.pages.forEach(el => {
          // Сортировка для отображения в таблице языка по умолчанию первым в списке
          el.langs.sort(function(a, b) {
            return a.lang_id === defaultLang.lang_id ? -1 : b.lang_id === defaultLang.lang_id ? 1 : 0
          })
          // сортировка для отображения имени страницы для языка по умолчанию первым в списке
          el.names.sort(function(a, b) {
            return a.lang_id === defaultLang.lang_id ? -1 : b.lang_id === defaultLang.lang_id ? 1 : 0
          })
        })
        this.firstActivePage = this.pages.find(el => el.status === 'active')
        if (this.firstActivePage === undefined) {
          this.link = ''
        } else {
          this.link = `${this.event.link}/${this.event.default_language.short}/${this.firstActivePage.index}`
        }
      }
      this.pagination.total = data.meta.total
    },
    tableChange(e) {
      this.pagination = e
      this.tableUpdate()
    },
    async deletePage(id) {
      try {
        this.tableLoading = true
        await apiClient.delete(`admin/events/${this.event.id}/pages/${id}`)
        this.$notification.success({
          message: 'Success',
          description: 'Page deleted successfully',
          duration: 2,
        })
        this.tableUpdate()
      } catch (e) {
        this.$notification.error({
          message: 'Error',
          description: e.message,
        })
      } finally {
        this.tableLoading = false
      }
    },
    openPage() {
      this.$router.push(`/events/${this.event.id}/page-new`)
    },
  },
  created() {
    const _this = this

    this.fetchEvent(this.$route.params.event)
      .then(() => {
        _this.tableUpdate()
      })
  },
}
</script>

<style lang="scss">
  .event-pages-content {
    .ant-table-row {
      cursor: pointer;
    }
    .link {
      font-size: 14px;
      margin-bottom: 32px;
      display: block;
    }
  }
</style>
